* {
  /* outline: 1px solid red; */
}

head,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background: #3a4750;
  color: #eee;
}

a {
  color: goldenrod;
}

.icon-button {
  padding: 0;
  border: none;
  border-radius: 16%;
  width: 32px;
  height: 32px;
  margin: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.flex {
  flex: 1;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-center {
  justify-content: center;
}

.align-stretch {
  align-items: stretch;
}

.align-center {
  align-items: center;
}

.container {
  margin-top: 10%;
  width: 100%;
}

.header {
  margin: 32px;
}

.file-input {
  margin: 8px;
}

.media-control {
  margin: 8px;
  width: 512px;
}

.pedal-down {
  transition: all 0.05s linear;
  margin: 8px;
  padding: 8px;
  border: 4px solid black;
  background: darkgray;
  color: black;
}

.pedal-up {
  transition: all 0.05s linear;
  margin: 8px;
  padding: 8px;
  border: 4px solid black;
  background: white;
  color: black;
}

.piano {
  margin: 16px;
  border: 4px solid black;
}
